import React, { useEffect, useState, useCallback } from "react";
import { gql, useQuery } from "urql";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { sortBy } from "lodash";
import { Tabs, Tab } from "react-bootstrap";
import { Party, PartyType } from "../../types.generated";

import {
    CollateralView,
    CashAccountView,
    TransactionView,
    TradingManagerView,
    FxView,
    CashLadderView,
    ReportsView,
    CounterpartyView,
    FxRiskView,
    PortfolioManagerCommentTabs,
    DashboardView,
    PreTradeView
} from "../../components/portfoliomanager";
import { SelectDropdown } from "../../components/SelectDropdown";

const getClients = gql`
    query {
        parties(filter: { typeIn: [Client], statusIn: [Confirmed] }) {
            _id
            name
            types
        }
    }
`;

const Checkbox = (props) => <input type="checkbox" {...props} />;

type SelectClientsPropsType = {
    selectedClientId: string;
    setSelectedClientId: (id: string) => void;
};

const SelectClients = ({ selectedClientId, setSelectedClientId }: SelectClientsPropsType): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({ query: getClients });

    useEffect(() => {
        let title = "PM - All portfolios";
        if (selectedClientId && selectedClientId !== "all" && data && data.parties) {
            const client = data.parties.find((x) => x._id == selectedClientId);
            title = `PM - ${client?.name}`;
        }
        document.title = title;
    }, [selectedClientId, data]);

    if (fetching) return <div>Loading...</div>;
    if (error) return <div>`Error! ${error.message}`</div>;

    let options = [];
    data.parties.forEach((party: Partial<Party>) => {
        if (party.types.includes(PartyType.Fund) || party.types.includes(PartyType.Mandate)) {
            options.push({ key: party._id, text: party.name });
        }
    });

    options = sortBy(options, "text");
    options.unshift({ key: "all", text: "All" }); // 'All' option

    return (
        <div className="row">
            <div className="col-12 col-sm-4">
                <SelectDropdown
                    placeholder="All"
                    className="form-control form-select"
                    defaultValue={selectedClientId}
                    options={options}
                    onChange={(e) => {
                        setSelectedClientId(e.key);
                    }}
                />
            </div>
        </div>
    );
};

const allTabs = {
    transactions: "transactions",
    positions: "positions",
    cashAccounts: "cashAccounts",
    collateral: "collateral",
    fx: "fx",
    cashLadder: "cashLadder",
    counterparties: "counterparties",
    fxRisk: "fxRisk",
    reports: "reports",
    comment: "comment",
    dashboard: "dashboard",
    preTrade: "preTrade"
};

export const PortfolioManager = (): React.ReactElement => {
    const { id, tab } = useParams<"id" | "tab">();
    const [activeTab, setActiveTab] = useState(tab || allTabs.transactions);
    const [checkedTransaction, setCheckedTransaction] = useState(false);
    const [checked2, setChecked2] = useState(true);
    const [tradingView, setTradingView] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handleCheckboxChangeTransaction = (e) => {
        setCheckedTransaction(e.target.checked);
    };

    const handleCheckboxChange2 = (e) => {
        setChecked2(e.target.checked);
    };

    const handleTradingViewChange = (e) => {
        setTradingView(e.target.checked);
    };

    const switchTab = useCallback(
        (tab: string) => {
            setActiveTab(tab);
            if (!(tab in allTabs)) {
                navigate("/pm/all/" + allTabs.transactions + location.search, { replace: true });
            }

            if (id) {
                navigate(`/pm/${id}/${tab}${location.search}`);
            } else {
                navigate(`/pm/all/${tab}${location.search}`);
            }
        },
        [id, navigate, location.search]
    );

    const trans_link = id === "all" || !id ? "/transactions" : "/transactions?qClientId=" + id;

    return (
        <div>
            <div className="row mb-4">
                <div className="col-10">
                    <h4>Client</h4>
                    <SelectClients
                        selectedClientId={id}
                        setSelectedClientId={(id) => {
                            if (tab) {
                                navigate(`/pm/${id}/${tab}${location.search}`);
                            } else {
                                navigate(`/pm/${id}${location.search}`);
                            }
                        }}
                    />
                </div>
            </div>

            <Tabs onSelect={switchTab} activeKey={activeTab} transition={false}>
                <Tab eventKey="transactions" title="Transactions">
                    {activeTab === "transactions" && (
                        <div>
                            <div>
                                <label>
                                    <Checkbox checked={checkedTransaction} onChange={handleCheckboxChangeTransaction} />
                                    <span>
                                        {"Show all types and history (Or go to "} <a href={trans_link}> /transactions</a>{" "}
                                        {"for detailed view.)"}
                                    </span>
                                </label>
                            </div>
                            <TransactionView viewAll={checkedTransaction} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="positions" title="Positions">
                    {activeTab === "positions" && (
                        <div>
                            <div className="mb-4">
                                <label>
                                    <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
                                    <span>Look through</span>
                                </label>
                                <label className="ms-4">
                                    <Checkbox checked={tradingView} onChange={handleTradingViewChange} />
                                    <span>Trading view</span>
                                </label>
                            </div>
                            <TradingManagerView lookThrough={checked2} tradingView={tradingView} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="cashAccounts" title="Cash accounts">
                    {activeTab === "cashAccounts" && <CashAccountView />}
                </Tab>
                <Tab eventKey="collateral" title="Collateral">
                    {activeTab === "collateral" && <CollateralView />}
                </Tab>
                <Tab eventKey="fx" title="FX">
                    {activeTab === "fx" && <FxView />}
                </Tab>
                <Tab eventKey="cashLadder" title="Cash ladder">
                    {activeTab === "cashLadder" && <CashLadderView />}
                </Tab>
                <Tab eventKey="counterparties" title="Counterparties">
                    {activeTab === "counterparties" && <CounterpartyView />}
                </Tab>
                <Tab eventKey="fxRisk" title="FX risk">
                    {activeTab === "fxRisk" && (
                        <div>
                            <div className="mb-4">
                                <label>
                                    <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
                                    <span>Look through</span>
                                </label>
                            </div>
                            <FxRiskView lookThrough={checked2} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="reports" title="Reports">
                    {activeTab === "reports" && (
                        <div>
                            <div className="mb-4">
                                <label>
                                    <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
                                    <span>Look through</span>
                                </label>
                            </div>
                            <ReportsView lookThrough={checked2} />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="comment" title="Comment">
                    {activeTab === "comment" && <PortfolioManagerCommentTabs />}
                </Tab>
                <Tab eventKey="dashboard" title="Dashboard">
                    {activeTab === "dashboard" && (
                        <div>
                            <DashboardView />
                        </div>
                    )}
                </Tab>
                <Tab eventKey="preTrade" title="Pre trade">
                    {activeTab === "preTrade" && (
                        <div>
                            <div className="mb-4">
                                <label>
                                    <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
                                    <span>Look through</span>
                                </label>
                            </div>
                            <PreTradeView lookThrough={checked2} />
                        </div>
                    )}
                </Tab>
            </Tabs>
        </div>
    );
};
